import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Validator } from '@/App/Mixins'
import i18n from '@/plugins/i18n'

@Component
export default class TimePicker extends Mixins(Validator) {
  @Prop({ default: '' })
  public value: string

  @Prop({ default: false })
  public clearable: boolean

  @Prop({ default: false })
  public required: boolean

  @Prop({ default: i18n.tc('pickers.timePicker.defaultLabelText') })
  public label: string

  public menu = false
  public time = ''

  @Watch('value')
  private valueChanged(val: string): void {
    this.time = val
  }

  private mounted():void {
    this.time = this.value
  }
}
