import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Validator } from '@/App/Mixins'
import { default as dayjs } from 'dayjs'
import i18n from '@/plugins/i18n'
import DatePicker from '@/App/Components/Pickers/DatePicker/Date-Picker.vue'
import TimePicker from '@/App/Components/Pickers/TimePicker/Time-Picker.vue'

@Component({
  components: { DatePicker, TimePicker }
})
export default class DateTimePicker extends Mixins(Validator) {
  @Prop({ default: false })
  public required: boolean

  @Prop({ default:  i18n.tc('pickers.dateTimePicker.defaultDateLabel') })
  public dateLabel: string

  @Prop({ default: i18n.tc('pickers.dateTimePicker.defaultTimeLabel') })
  public timeLabel: string

  @Prop({ default: 'timestamp' })
  public returnDateFormat: 'timestamp' | 'unix' | string

  @Prop({ default: dayjs().format('YYYY-MM-DD') })
  public defaultDate: string

  @Prop({ default: '00:00' })
  public defaultTime: string

  public date = ''
  public time = ''

  private dateFormat(date: string): number | string {
    if (this.returnDateFormat === 'timestamp') {
      return dayjs(date).unix() * 1000
    } else if (this.returnDateFormat === 'unix') {
      return dayjs(date).unix()
    } else {
      return dayjs(date).format(this.returnDateFormat)
    }
  }

  @Watch('date')
  private dateChanged(date: string): void {
    let dateTime: string

    if (this.time.length === 5) {
      dateTime = `${date} ${this.time}`
    } else {
      dateTime = `${date} 00:00`
    }

    this.$emit('input', this.dateFormat(dateTime))
  }

  @Watch('time')
  private timeChanged(time: string): void {
    let dateTime: string
    const timeLength = time.length

    if (this.date && timeLength === 5) {
      dateTime = `${this.date} ${time}`
    } else if (!this.date && timeLength === 5) {
      dateTime = `${dayjs().format('YYYY-MM-DD')} ${time}`
    } else if (this.date && timeLength !== 5) {
      dateTime = `${this.date} 00:00`
    } else {
      dateTime = `${dayjs().format('YYYY-MM-DD')} 00:00`
    }

    this.$emit('input', this.dateFormat(dateTime))
  }

  private mounted(): void {
    this.date = this.defaultDate
    this.time = this.defaultTime

    this.$emit('input', this.dateFormat(`${this.date} ${this.time}`))
  }
}
