import { Component, Mixins } from 'vue-property-decorator'
import { Validator } from '@/App/Mixins'
import { DateTimePicker } from '@/App/Components/Pickers'

@Component({
  components: { DateTimePicker }
})
export default class IntervalPicker extends Mixins(Validator) {
  public isOpen = false
  public form = false
  public dateFrom = 0
  public dateTo = 0
  public confirm: any = null

  public open(toPromise = false): void | Promise<DateTimeInterval> {
    this.isOpen = true

    if (toPromise) {
      return new Promise(resolve => {
        this.confirm = resolve
      })
    }
  }

  public close(): void {
    this.isOpen = false

    if (this.confirm) {
      this.confirm(false)
      this.confirm = null
    }
  }

  public onIntervalSelected(): void {
    const data: DateTimeInterval = {
      from: this.dateFrom,
      to: this.dateTo
    }

    this.$emit('input', data)

    if (this.confirm) {
      this.confirm(data)
      this.confirm = null
    }

    this.close()
  }
}

export interface DateTimeInterval {
  from: number
  to: number
}
